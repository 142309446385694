<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Feedback" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs4" v-if="tabValue === 0">
                <va-input
                  label="Name field *"
                  v-model="nameField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formfeedback_name"
                  :error-messages="errors.formfeedback_name"
                  @input="delete errors.formfeedback_name"
                />
                <va-input
                  label="Email field *"
                  v-model="emailField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formfeedback_email"
                  :error-messages="errors.formfeedback_email"
                  @input="delete errors.formfeedback_email"
                />
                <va-input
                  label="Phone field *"
                  v-model="phoneField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formfeedback_phone"
                  :error-messages="errors.formfeedback_phone"
                  @input="delete errors.formfeedback_phone"
                />
                <va-input
                  label="Message field *"
                  v-model="messageField"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formfeedback_message"
                  :error-messages="errors.formfeedback_message"
                  @input="delete errors.formfeedback_message"
                />
                <va-input
                  label="Submit Button *"
                  v-model="submitButton"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.formfeedback_submit"
                  :error-messages="errors.formfeedback_submit"
                  @input="delete errors.formfeedback_submit"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Success title *"
                  v-model="successTitle"
                  :messages="['The recommended number of characters is 90']"
                  :error="!!errors.formfeedback_success_title"
                  :error-messages="errors.formfeedback_success_title"
                  @input="delete errors.formfeedback_success_title"
                />
                <va-input
                  label="Success message *"
                  v-model="successMessage"
                  type="textarea"
                  :messages="['The recommended number of characters is 300']"
                  :error="!!errors.formfeedback_success_message"
                  :error-messages="errors.formfeedback_success_message"
                  @input="delete errors.formfeedback_success_message"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      nameField: '',
      emailField: '',
      phoneField: '',
      messageField: '',
      submitButton: '',

      successTitle: '',
      successMessage: '',

      errors: [],

      tabTitles: ['Main', 'Success popup'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        formfeedback_name: this.nameField,
        formfeedback_email: this.emailField,
        formfeedback_phone: this.phoneField,
        formfeedback_message: this.messageField,
        formfeedback_submit: this.submitButton,

        formfeedback_success_title: this.successTitle,
        formfeedback_success_message: this.successMessage,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.formfeedback_name || !!this.errors.formfeedback_email || !!this.errors.formfeedback_phone ||
          !!this.errors.formfeedback_message || !!this.errors.formfeedback_submit
      } else if (tab === 1) {
        return !!this.errors.formfeedback_success_title || !!this.errors.formfeedback_success_message
      }
      return false
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/8`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/validate/8`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/8`)
        .then(response => {
          // console.log(response.data)
          this.nameField = response.data.formfeedback_name
          this.emailField = response.data.formfeedback_email
          this.phoneField = response.data.formfeedback_phone
          this.messageField = response.data.formfeedback_message
          this.submitButton = response.data.formfeedback_submit

          this.successTitle = response.data.formfeedback_success_title
          this.successMessage = response.data.formfeedback_success_message
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
